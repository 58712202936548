import { useState, useEffect, useRef } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { INewsCard } from '@obeta/models/lib/models/News/News'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { useStrapiGql } from '@obeta/app-bootstrap/lib/hooks/useStrapiGql'

export const newsQuery = gql`
  query obetaNews(
    $filters: ObetaNewsFiltersInput
    $pagination: PaginationArg = {}
    $sort: [String] = []
    $publicationState: PublicationState = LIVE
  ) {
    obetaNewsCollection(
      filters: $filters
      pagination: $pagination
      sort: $sort
      publicationState: $publicationState
    ) {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          url
          pinned
          teaserText
          startDate
          publishedAt
          endDate
          category
          title
          teaser {
            data {
              id
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`

interface INewsOptions<Filters> {
  config?: {
    pagination?: {
      start: number
      limit: number
    }
    sort?: string
    shouldRefetch?: number
    filters: Filters
  }
  hasCachedNewsAndIsNotExpired?: boolean
}

export enum LoadingStatus {
  Loaded = 'loaded',
  Fetching = 'fetching',
  Idle = 'idle',
}

const transformNewsListData = (data) => {
  return data.map((data) => {
    const news = {
      id: data.id,
      ...data.attributes,
      imageUrl: data.attributes.teaser.data?.attributes.url,
      imageAlt: data.attributes.teaser.data?.attributes.alternativeText,
    }
    delete news.__typename
    delete news.teaser

    return news
  })
}

export const useNews = <Filters extends object>(options?: INewsOptions<Filters>) => {
  const { pagination, sort, shouldRefetch, filters } = options?.config || {}

  const apolloClient = useStrapiGql()

  const [news, setNews] = useState<INewsCard[]>([])
  const [total, setTotal] = useState(0)
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.Idle)
  const [error, setError] = useState<unknown>(null)

  const client = useApolloClient(apolloClient)

  const isMounted = useRef(true)
  const currentDate = new Date()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const getNews = async () => {
      if (options?.hasCachedNewsAndIsNotExpired) return
      if (!isMounted.current) return

      setLoadingStatus(LoadingStatus.Fetching)

      const getVariables = () => {
        const variables = {
          pagination: pagination ? pagination : {},
          sort: sort ? sort : 'sortDate:desc',
          filters: {
            endDate: { gte: currentDate },
            startDate: { lte: currentDate },
            ...filters,
          },
        }

        return variables
      }

      try {
        const response = await client.query({
          query: newsQuery,
          variables: getVariables(),
        })

        const result = response.data.obetaNewsCollection

        const newsList = transformNewsListData(result.data)

        if (!pagination || pagination?.start === 0) {
          setNews(newsList)
        } else {
          setNews([...news, ...newsList])
        }

        setTotal(result.meta.pagination.total)

        setLoadingStatus(LoadingStatus.Loaded)
      } catch (err: unknown) {
        if (err instanceof Error) {
          handleError(err)
        }
        setError(err)
        setLoadingStatus(LoadingStatus.Idle)
      }
    }

    getNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch])

  return { news, total, loadingStatus, error }
}
